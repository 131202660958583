<template>
  <div class="table-pagination w-100">
    <b-row>
      <b-col cols="3">
        <div class="pagination-content d-flex">
          <div class="page-counts d-flex align-center text-sm-center">
            <span>{{ paginationData.from }} - {{ paginationData.to }} of {{ paginationData.total }}</span>
          </div>
          <div class="pl-2">
            <HeroVueSelect
              id="paginationlenght"
              v-model="perPages"
              label=""
              placeholder=""
              :multiple="false"
              :clearable="false"
              :options="perPagesRange"
              @input="handlePerPageChange"
            />
          </div>
        </div>
      </b-col>
      <b-col cols="9">
        <div class="w-100 float-right">
          <b-pagination
            v-model="paginationData.currPage"
            aria-controls="channel-performance-table"
            :total-rows="paginationData.total"
            :per-page="paginationData.perPages"
            align="right"
            @change="handlePageChange($event)"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  Storage,
  defaultPerPage,
  perPagesRange,
} from '@/utils/utils'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import './style.scss'

export default {
  name: 'PaginationComponent',
  components: {
    HeroVueSelect,
  },
  props: {
    paginationData: {
      type: Object,
      default: () => ({
        currPage: 1,
        from: 0,
        to: 0,
        total: 0,
        totalPages: 0,
        perPages: defaultPerPage,
      }),
    },
  },
  data() {
    return ({
      defaultPerPage,
      perPagesRange,
      currPage: 3,
      totalPages: 1,
    })
  },
  computed: {
    perPages() {
      return this.paginationData.perPages ? this.paginationData.perPages.toString() : '20'
    },
  },
  watch: {
    paginationData(newData) {
      const { currPage, totalPages, perPages } = newData
      this.currPage = currPage
      this.totalPages = totalPages
      this.defaultPerPage = perPages
    },
  },
  mounted() {
    const { currPage, totalPages, perPages } = this.paginationData
    this.currPage = currPage
    this.totalPages = totalPages
    this.defaultPerPage = perPages || Storage.getItem('pagination-per-page')
  },
  methods: {
    handlePageChange(value) {
      this.currPage = value
      this.$emit('onPageChange', value)
    },
    handlePerPageChange(value) {
      this.currPage = 1
      const newValue = parseInt(value, 0)
      this.defaultPerPage = newValue

      Storage.setItem('pagination-per-page', newValue)
      this.$emit('onPerPageChange', newValue)
    },
  },
}
</script>
