<template>
  <div class="input-date-component">
    <input
      ref="input0"
      :placeholder="formats[0]"
      :value="datas[0]"
      :class="`input-${formats[0]}`"
      tabindex="1"
      :maxlength="formats[0] ? formats[0].length : 2"
      @input="handleInput($event, 0)"
      @focus="handleFocus($event)"
    >
    <feather-icon
      icon="MinusIcon"
      size="10"
      @click="handleClearResult(i)"
    />
    <input
      ref="input1"
      :placeholder="formats[1]"
      :value="datas[1]"
      :class="`input-${formats[1]}`"
      tabindex="1"
      :maxlength="formats[1] ? formats[1].length : 2"
      @input="handleInput($event, 1)"
      @focus="handleFocus($event)"
    >
    <feather-icon
      icon="MinusIcon"
      size="12"
      @click="handleClearResult(i)"
    />
    <input
      ref="input2"
      :placeholder="formats[2]"
      :value="datas[2]"
      :class="`input-${formats[2]}`"
      tabindex="1"
      :maxlength="formats[2] ? formats[2].length : 2"
      @input="handleInput($event, 2)"
      @focus="handleFocus($event)"
    >
  </div>
</template>

<script>
import './style.scss'

export default {
  name: 'InputDateComponent',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: 'dd/mm/yyyy',
    },
    separator: {
      type: String,
      default: '/',
    },
    valid: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      datas: [],
      formats: [],
      monthIndex: 0,
      yearIndex: 0,
    }
  },
  watch: {
    valid(newValue) {
      Object.keys(this.$refs).forEach(key => {
        this.$refs[key].valid = newValue
        if (newValue) {
          this.$refs[key].classList.remove('invalid')
        } else {
          this.$refs[key].classList.add('invalid')
        }
      })
    },
  },
  mounted() {
    this.formats = this.format.split(this.separator)
    if (!this.value) return
    this.datas = this.value.split(this.separator)
    this.formats.forEach((item, i) => {
      if (item.includes('m')) this.monthIndex = i
      if (item.includes('y')) this.yearIndex = i
    })
  },
  methods: {
    /**
         * Get how many days of current month
         */
    daysInMonth(year, month) {
      return new Date(year, month, 0).getDate()
    },
    handleInput(e, index) {
      const { value } = e.target
      const currentYear = new Date().getFullYear()
      const newvalue = +value
      const tempData = [
        ...this.datas.slice(0, index),
        newvalue,
        ...this.datas.slice(index + 1),
      ]

      // Limit max/min between +/- 5 years
      if ((index === 2 && value.length < 4) || (newvalue > currentYear + 5) || (newvalue < currentYear - 5)) return

      const tempValue = `${tempData[0]}${this.separator}${tempData[1]}${this.separator}${tempData[2]}`
      const formated = this.$moment(tempValue, 'DD/MM/YYYY').format('MM/DD/YYYY')
      const maxDates = this.daysInMonth(tempData[this.yearIndex], tempData[this.monthIndex])

      if (
        formated === 'Invalid Date' || formated === 'Invalid date'
                || ((this.formats[index].includes('d') && newvalue > maxDates) || newvalue <= 0)
                || ((this.formats[index].includes('m') && newvalue > 12) || newvalue <= 0)
      ) {
        e.target.valid = false
        e.target.classList.add('invalid')
        return
      }

      e.target.valid = true
      e.target.classList.remove('invalid')

      this.datas = tempData
      this.$emit('onChange', {
        defaultFormat: tempValue,
        standarFormat: formated,
      })

      if (value.length === this.formats[index].length && index + 1 < 3) {
        this.$refs[`input${index + 1}`].focus()
        this.$refs[`input${index + 1}`].select()
      }
    },
    handleFocus(e) {
      e.target.select()
    },
  },
}
</script>
