<template>
  <div class="export-component">
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      style="width: 150px"
      @click="handleDownload"
    >
      <feather-icon
        icon="DownloadIcon"
        size="16"
      />
      Export
    </b-button>
  </div>
</template>

<script>
import { parseTime } from '@/utils/utils'
import './style.scss'

export default {
  name: 'ExportExcelComponent',
  props: {
    header: {
      type: Array,
      default: () => [],
    },
    bodyData: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
      default: 'Channel_Performance_Report',
    },
  },
  data() {
    return {
      exportData: [],
    }
  },
  watch: {
    bodyData(newData) {
      this.reduceData(newData)
    },
  },
  mounted() {
    const nameSubfix = this.$moment().format('YYYYMMDD_HHmmss')
    this.exportName = `${this.name}_${nameSubfix}`
    this.reduceData(this.bodyData)
  },
  methods: {
    handleDownload() {
      if (this.header.length === 0) return
      import('@/utils/Export2Excel').then(({ export_json_to_excel }) => {
        const headerText = this.header.reduce((res, item) => [...res, item.text], [])
        const keys = this.header.reduce((res, item) => [...res, item.key], [])
        const test = this.exportData.map(item => {
          const startOfChild = '|---'
          const baseSpace = '----'
          switch (item.level) {
            case 1: return {
              ...item,
              name: `${startOfChild} ${item.name}`,
            }
            case 2: return {
              ...item,
              name: `${startOfChild}${baseSpace} ${item.name}`,
            }
            case 3: return {
              ...item,
              name: `${startOfChild}${baseSpace}${baseSpace} ${item.name}`,
            }
            default: return item
          }
        })
        const data = this.formatJson(keys, test)

        export_json_to_excel({
          header: headerText,
          data,
          filename: this.exportName,
          autoWidth: true,
          bookType: 'xlsx',
        })
      }, e => {
        console.log(e)
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        if (j === 'timestamp') {
          return parseTime(v[j])
        }
        return v[j]
      }))
    },
    reduceData(data) {
      this.exportData = Object.values(data).reduce((res, item) => [...res, ...item], [])
    },
  },
}
</script>
