<template>
  <div class="filter--product-component">
    <HeroVueSelectMultiple
      id="product_family"
      v-model="selectedProductFamilys"
      label="Product Family"
      placeholder="Select Product Family"
      :options="productData.productFamily"
      @input="handleProductFamilyChange"
    />
    <HeroVueSelectMultiple
      id="product"
      v-model="selectedProducts"
      label="Product"
      placeholder="Select Product"
      :disabled="filteredProduct.length === 0"
      :options="filteredProduct"
      @input="handleProductChange"
    />
  </div>
</template>

<script>
import HeroVueSelectMultiple from '@/views/components/form/selects/HeroVueSelectMultiple.vue'
import CategoryDropdownFactory from '@/factories/Campaign/CategoryDropdown'

export default {
  name: 'FilterProductComponent',
  components: {
    HeroVueSelectMultiple,
  },
  props: {
    productData: {
      type: Object,
      default: () => ({
        productFamily: [],
        product: [],
      }),
    },
    value: {
      type: Object,
      default: () => ({
        productFamily: [],
        product: [],
      }),
    },
  },
  data() {
    return ({
      filteredProduct: [],
      selectedProductFamilys: [],
      selectedProducts: [],
    })
  },
  watch: {
    productData() {
      this.selectedProductFamilys.forEach(id => {
        const products = this.getProductByProductFamilyId(id)
        this.filteredProduct = [
          ...this.filteredProduct,
          ...products,
        ]
        this.getUniqueProducts()
      })
    },
    value() {
      this.selectedProductFamilys = this.value.productFamilys || []
      this.selectedProducts = this.value.products || []
      this.selectedProductFamilys.forEach(id => {
        const products = this.getProductByProductFamilyId(id)
        this.filteredProduct = [
          ...this.filteredProduct,
          ...products,
        ]
        this.getUniqueProducts()
      })
    },
  },
  mounted() {
    this.selectedProductFamilys = this.value.productFamilys || []
    this.selectedProducts = this.value.products || []
  },
  methods: {
    handleProductFamilyChange(productvalue) {
      this.selectedProductFamilys = productvalue

      if (productvalue.length === 0) {
        this.filteredProduct = []
        this.selectedProducts = []
      } else {
        this.filteredProduct = []
        productvalue.forEach(id => {
          const products = this.getProductByProductFamilyId(id)
          this.filteredProduct = [
            ...this.filteredProduct,
            ...products,
          ]
          this.getUniqueProducts()
        })
      }

      this.$emit('onChange', {
        productFamilys: this.selectedProductFamilys,
        products: this.selectedProducts,
      })
    },
    handleProductChange(value) {
      console.log(value, 'value')
      console.log(this.selectedProducts, 'this.selectedProducts')
      this.selectedProducts = value
      this.$emit('onChange', {
        productFamilys: this.selectedProductFamilys,
        products: this.selectedProducts,
      })
    },
    getProductByProductFamilyId(id) {
      // eslint-disable-next-line camelcase
      const ProductOptions = CategoryDropdownFactory.createFromJsonArray(this.productData.product.filter(x => x.product_type_id === id))
      return ProductOptions
    },
    getUniqueProducts() {
      const ids = this.filteredProduct.map(o => o.value)
      const filtered = this.filteredProduct.filter(({ value }, index) => !ids.includes(value, index + 1))
      this.filteredProduct = filtered
    },

  },
}
</script>
