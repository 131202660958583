<template>
  <div class="table-settings">
    <b-dropdown id="tableActions" text="Table Actions" class="w-100">
      <b-dropdown-item @click="openSettingDialog">
        Edit Columns
      </b-dropdown-item>
      <b-dropdown-item @click="openViewsHistoryDialog">
        Table Views History
      </b-dropdown-item>
    </b-dropdown>

    <b-modal
      id="modal-setting-filter"
      scrollable
      title="Choose which columns you see"
      cancel-variant="outline-secondary"
      size="lg"
    >
      <b-row class="setting-dialog">
        <b-col cols="6">
          <HeroInputText
            id="search-string"
            v-model="searchString"
            label="Search column"
            placeholder="Search column"
          />
          <h3>
            Columns
            <b-button variant="secondary" class="float-right ml-1" @click="toggleSelectAll()">
              {{ isSelectAll ? 'Unselect All' : 'Select All' }}
            </b-button>
          </h3>
          <v-list class="mt-2">
            <v-list-tile v-for="(item, index) in [...tableColumns]" :key="index">
              <v-list-tile-action>
                <label :htmlFor="item.key" class="d-flex">
                  <v-flex>
                    <input
                      type="checkbox"
                      class="mr-2"
                      :name="item.key"
                      :checked="item.required || selectedColumnsKey.includes(item.key)"
                      :disabled="item.required"
                      @change="handleSelectColumn($event, item.key)"
                    >
                  </v-flex>
                  <v-flex>{{ item.text }}</v-flex>
                </label>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </b-col>
        <b-col cols="6">
          <h3 class="mb-3">
            Selected Columns ({{ defaultColumns.length + selectedColulmns.length }})
          </h3>
          <div class="selected-columns list-column pr-2">
            <div class="default-columns">
              <div v-for="(element, index) in defaultColumns" :key="index" class="list-column-item mb-1">
                <feather-icon
                  icon="DragIcon"
                  size="16"
                /> {{ element.text }}
              </div>
            </div>
            <draggable v-model="selectedColulmns" @start="drag=true" @end="drag=false">
              <div v-for="(element, index) in selectedColulmns" :key="index" class="list-column-item mb-1">
                <feather-icon
                  icon="GridIcon"
                  size="16"
                />
                <div class="pl-1 w-100" style="padding-top: 2px">
                  {{ element.text }}
                </div>
                <feather-icon
                  icon="XIcon"
                  size="16"
                  class="float-right cursor-pointer"
                  @click="handleUnselectColumn(element.key)"
                />
              </div>
            </draggable>
          </div>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button variant="secondary" class="float-right ml-1" @click="handleCancel()">
          Cancel
        </b-button>
        <b-button variant="primary" class="float-right ml-1" @click="handleOk()">
          Apply
        </b-button>
      </template>
    </b-modal>
    <!-- Views history dialog -->
    <ViewsHistoryComponent
      :is-modal-open="isViewsHistoryOpen"
      :views="viewsHistory"
      :default-columns="defaultColumns"
      @onOk="handleModalViewsOk"
      @onCancel="handleModalViewsCancel"
      @onClear="handleClearHistories"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {
  Storage,
} from '@/utils/utils'
// import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import ViewsHistoryComponent from '../ViewsHistoryComponent/ViewsHistoryComponent.vue'
import './style.scss'

export default {
  name: 'SettingComponent',
  components: {
    draggable,
    ViewsHistoryComponent,
    HeroInputText,
    // HeroVueSelect,
  },
  props: {
    columns: {
      type: Array,
      required: true,
      default: () => [],
    },
    filteredColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isSettingOpen: false,
      isSettingUpdated: false,
      defaultColumns: [],
      selectedColulmns: [],
      selectedColumnsKey: [],
      tableColumns: [],
      searchString: '',
      notRequireColumns: [],
      isSelectAll: false,
      timer: null,
      dataInObj: {},
      viewsHistory: [],
      isViewsHistoryOpen: false,
      tableAction: '',
      tableActions: [{ text: 'Edit Columns', value: 'edit_columns' }, { text: 'Table Views History', value: 'table_views_history' }],
    }
  },
  watch: {
    searchString(newVal) {
      if (!newVal) {
        this.tableColumns = this.columns
        return
      }
      if (this.timer) {
        clearTimeout(this.timer)
      }

      this.timer = setTimeout(() => {
        this.tableColumns = this.columns.filter(({ text }) => text.toLowerCase().includes(newVal.toLowerCase()))
      }, 300)
    },
    columns(newVal) {
      this.tableColumns = newVal
    },
    selectedColulmns(newData) {
      this.isSettingUpdated = true
      this.selectedColumnsKey = newData.map(({ key }) => key)
    },
  },
  created() {
    const viewsHistory = Storage.getItem('views-history') || {}
    this.viewsHistory = viewsHistory.histories || []
    this.defaultColumns = this.columns.filter(column => column.required)
    this.tableColumns = [...this.columns]
    this.notRequireColumns = this.columns.filter(column => !column.required)
    this.dataInObj = this.notRequireColumns.reduce((res, item) => ({
      ...res,
      [item.key]: item,
    }), {})
    this.initData()
  },
  methods: {
    initData() {
      if (this.filteredColumns.length > 0) {
        this.selectedColulmns = this.filteredColumns.filter(({ key }) => this.dataInObj[key])
        this.selectedColumnsKey = this.filteredColumns.map(({ key }) => key)
        this.isSelectAll = this.selectedColulmns.length === this.notRequireColumns.length
        return
      }

      this.selectedColulmns = this.notRequireColumns
      this.selectedColumnsKey = this.selectedColulmns.map(({ key }) => key)
      this.isSelectAll = this.selectedColulmns.length === this.notRequireColumns.length
    },
    openSettingDialog() {
      this.isSettingOpen = true
      this.$bvModal.show('modal-setting-filter')
    },
    openViewsHistoryDialog() {
      this.isViewsHistoryOpen = true
    },
    handleModalViewsOk(data) {
      this.isViewsHistoryOpen = false
      if (data.length === 0) {
        return
      }

      this.selectedColulmns = data.slice(1)
      this.$emit('onSettingUpdated', this.selectedColulmns)
    },
    handleModalViewsCancel() {
      this.isViewsHistoryOpen = false
    },
    handleClearHistories() {
      this.viewsHistory = []
    },
    handleSelectColumn(e, key) {
      console.log(e, 'e')
      console.log(key, 'key')
      const { checked } = e.target
      this.selectedColumnsKey = checked
        ? [...this.selectedColumnsKey, key]
        : this.selectedColumnsKey.filter(item => item !== key)

      this.calculateSelectedColumns()
    },
    handleUnselectColumn(key) {
      this.selectedColumnsKey = this.selectedColumnsKey.filter(item => item !== key)
      this.calculateSelectedColumns()
    },
    handleOk() {
      if (this.isSettingUpdated) {
        this.$emit('onSettingUpdated', this.selectedColulmns)
        this.viewsHistory = [
          ...this.viewsHistory,
          this.selectedColulmns,
        ]
      }
      this.isSettingOpen = false
      this.$bvModal.hide('modal-setting-filter')
    },
    handleCancel() {
      this.initData()
      this.isSettingOpen = false
      this.$bvModal.hide('modal-setting-filter')
    },
    calculateSelectedColumns() {
      this.selectedColulmns = this.selectedColumnsKey.map(key => this.dataInObj[key])
    },
    toggleSelectAll() {
      if (this.isSelectAll) {
        this.selectedColumnsKey = []
        this.selectedColulmns = []
        this.isSelectAll = false
        return
      }
      let newKeysCollection = []
      this.isSelectAll = true

      Object.keys(this.dataInObj).forEach(key => {
        if (!this.selectedColumnsKey.includes(key)) newKeysCollection = [...newKeysCollection, key]
      })
      this.selectedColumnsKey = [...this.selectedColumnsKey, ...newKeysCollection]
      this.calculateSelectedColumns()
    },
  },
}
</script>
