<template>
  <div class="filter--country">
    <HeroVueSelectMultiple
      id="country"
      v-model="selectedCountry"
      label="Country"
      placeholder="Select Country"
      :options="countryData"
      @input="handleDataChange"
    />
  </div>
</template>

<script>
import HeroVueSelectMultiple from '@/views/components/form/selects/HeroVueSelectMultiple.vue'

export default {
  name: 'FilterCountryComponent',
  components: {
    HeroVueSelectMultiple,
  },
  props: {
    countryData: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({
        country: [],
      }),
    },
  },
  data() {
    return ({
      selectedCountry: [],
    })
  },
  watch: {
    value() {
      this.selectedCountry = this.value.country
    },
  },
  mounted() {
    this.selectedCountry = this.value.country
  },
  methods: {
    handleDataChange(value) {
      this.$emit('onChange', { country: value })
    },
  },
}
</script>
