<template>
  <div class="filter--staff">
    <HeroVueSelectMultiple
      id="ams"
      v-model="results.ams"
      label="AMs"
      placeholder="Select AMs"
      :options="staffData.ams"
      @input="(value) => handleDataChange('ams', value)"
    />
    <HeroVueSelectMultiple
      v-show="!isOutsourceOptimizer"
      id="optimizers"
      v-model="results.optimizers"
      label="Optimizers"
      placeholder="Select Optimizers"
      :options="staffData.optimizers"
      @input="(value) => handleDataChange('optimizers', value)"
    />
  </div>
</template>

<script>
import store from '@/store'
import HeroVueSelectMultiple from '@/views/components/form/selects/HeroVueSelectMultiple.vue'

/* Fix outsource optimizer user by email */
// const outsourceOptimizerEmails = [
//   'nopdanai.j@heroleads.asia',
//   'manida.n@heroleads.asia',
//   'pause@heroleads.asia',
//   'kittipong.p@heroleads.asia',
//   'ratchawanlop.prem@heroleads.asia',
// ]

export default {
  name: 'FilterStaffComponent',
  components: {
    HeroVueSelectMultiple,
  },
  props: {
    staffData: {
      type: Object,
      default: () => ({
        ams: [],
        optimizers: [],
      }),
    },
    value: {
      type: Object,
      default: () => ({
        ams: [],
        optimizers: [],
      }),
    },
  },
  data() {
    return ({
      isOutsource: false,
      results: {
        ams: [],
        optimizers: [],
      },
    })
  },
  computed: {
    isOutsourceOptimizer() {
      const { roles } = store.getters['heroAiAuthentications/loginData']
      return roles.some(role => role.name === 'Contractor - Optimizer')
    },
  },
  watch: {
    value() {
      this.results = this.value
    },
  },
  mounted() {
    if (this.value) this.results = this.value
    this.handleSetOutsourceOptimizer()
  },
  methods: {
    handleDataChange(key, value) {
      this.results = {
        ...this.results,
        [key]: value,
      }
      this.$emit('onChange', this.results)
    },

    handleSetOutsourceOptimizer() {
      const user = store.getters['heroAiAuthentications/loginData']

      if (this.isOutsourceOptimizer) {
        const currentOptimizer = this.staffData.optimizers.find(optimizer => optimizer.value === user.id)

        if (currentOptimizer) {
          this.handleDataChange('optimizers', [currentOptimizer.value])
        }
      }
    },
  },
}
</script>
