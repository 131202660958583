<template>
  <div class="filter--date-picker-component">
    <b-row>
      <b-col cols="12" class="mb-1">
        <b-form-radio-group
          id="how-to-display-radio-group"
          v-model="formValue.howToDisplaySelected"
          name="how-to-display-radio-group"
        >
          <b-form-radio value="compare-by-cycles">
            Compare By Cycles
          </b-form-radio>
          <b-form-radio value="compare-by-dates">
            Compare By Dates
          </b-form-radio>
          <b-form-radio value="display-current-contract-cycles">
            Display Current Contract Cycles
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
      <b-col v-if="formValue.howToDisplaySelected === 'compare-by-cycles'" cols="6">
        <HeroInputNumber
          id="compare-periods"
          v-model="formValue.period"
          label="How many cycles should be compared?"
          :clearable="false"
          @blur="formValue.period = +formValue.period < 1 ? '1' : formValue.period"
        />
      </b-col>
      <b-col v-if="formValue.howToDisplaySelected === 'compare-by-dates'" cols="12">
        <CalendarComponent
          :value="formValue.datePicker"
          display-format="dd/MM/yyyy"
          @onChange="formValue.datePicker = $event"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import CalendarComponent from '../CalendarComponent/CalendarComponent.vue'

import './style.scss'

export default {
  name: 'DatePickerComponent',
  components: {
    HeroInputNumber,
    CalendarComponent,
  },
  filters: {
    dateToISOString(value) {
      return new Date(value).toISOString()
    },
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        howToDisplaySelected: 'compare-by-cycles',
        period: 1,
        datePicker: [],
      }),
    },
  },
  computed: {
    formValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
