<template>
  <div class="filter--campaign-data-component">
    <HeroInputText
      id="campaign-name"
      v-model="value.campaignName"
      label="Name"
      placeholder="Your campaign name"
      @input="(value) => handleDataChange('name', value)"
    />
    <HeroVueSelectMultiple
      id="status"
      v-model="value.kpi"
      label="KPI Unit"
      placeholder="Select KPI Unit"
      :options="campaignData.kpi"
      @input="(value) => handleDataChange('kpi', value)"
    />
    <HeroVueSelectMultiple
      id="status"
      v-model="value.objective"
      label="Objective"
      placeholder="Select Objective"
      :options="campaignData.objective"
      @input="(value) => handleDataChange('objective', value)"
    />
  </div>
</template>

<script>
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroVueSelectMultiple from '@/views/components/form/selects/HeroVueSelectMultiple.vue'
import './style.scss'

export default {
  name: 'FilterCampaignDataComponent',
  components: {
    HeroInputText,
    HeroVueSelectMultiple,
  },
  props: {
    campaignData: {
      type: Object,
      default: () => ({
        // industry: [],
        kpi: [],
        objective: [],
      }),
    },
    value: {
      type: Object,
      default: () => ({
        campaignName: '',
        kpi: [],
        objective: [],
      }),
    },
  },
  data() {
    return ({
      results: {},
      timer: null,
    })
  },
  watch: {
    value() {
      this.results = this.value
    },
  },
  mounted() {
    if (this.value) this.results = this.value
  },
  methods: {
    handleDataChange(key, value) {
      if (key === 'name') {
        this.timer = clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.results = {
            ...this.results,
            campaignName: value,
          }
          this.$emit('onChange', this.results)
        }, 300)
        return
      }

      this.results = {
        ...this.results,
        [key]: value,
      }
      this.$emit('onChange', this.results)
    },
  },
}
</script>
