<template>
  <div class="views-history-component">
    <b-modal
      id="views-history-dialog"
      scrollable
      title="Choose which Views you see"
      cancel-variant="outline-secondary"
      size="lg"
      @close="modal = false"
    >
      <b-row>
        <b-col cols="6">
          <h3>
            Histories
            <b-button variant="secondary" class="float-right ml-1" @click="clearHistory">
              Clear All Histories
            </b-button>
          </h3>
          <v-list>
            <v-list-tile v-for="(item, index) in histories" :key="index" class="pb-1">
              <v-list-tile-action :class="{'active': selectedViewId === index}" @click="handleViewClick(index, item)">
                <span class="d-flex cursor-pointer">
                  <v-flex># {{ index + 1 }}</v-flex>
                </span>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </b-col>
        <b-col cols="6">
          <h3 class="mb-3">
            Selected Columns ({{ selectedView.length }})
          </h3>
          <div class="selected-columns list-column pr-2">
            <div class="default-columns">
              <div v-for="(view, index) in selectedView" :key="index" class="list-column-item mb-1">
                <span class="d-flex">
                  <feather-icon
                    icon="GridIcon"
                    size="16"
                  />
                  <div class="pl-1 w-100" style="padding-top: 2px">
                    {{ view.text }}
                  </div>
                </span>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button variant="secondary" class="float-right ml-1" @click="handleCancel()">
          Cancel
        </b-button>
        <b-button variant="primary" class="float-right ml-1" @click="handleOk()">
          Apply
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  Storage,
} from '@/utils/utils'

import './style.scss'

export default {
  name: 'ViewsHistoryComponent',
  props: {
    views: {
      type: Array,
      required: true,
    },
    defaultColumns: {
      type: Array,
      required: true,
    },
    isModalOpen: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    modal: false,
    histories: [],
    selectedView: [],
    selectedViewId: null,
  }),
  watch: {
    isModalOpen(newValue) {
      if (newValue) {
        this.$bvModal.show('views-history-dialog')
      }
      this.modal = newValue
    },
    views(newValue) {
      const viewsHistory = Storage.getItem('views-history') || {}
      this.histories = newValue
      viewsHistory.activeId = null
      viewsHistory.histories = newValue
      Storage.setItem('views-history', viewsHistory)
    },
  },
  mounted() {
    const viewsHistory = Storage.getItem('views-history') || {}
    this.histories = this.views
    this.selectedViewId = viewsHistory.activeId ? viewsHistory.activeId : null
    this.selectedView = this.histories[this.selectedViewId] || []
  },
  methods: {
    handleOk() {
      const viewsHistory = Storage.getItem('views-history') || {}
      viewsHistory.activeId = this.selectedViewId ? this.selectedViewId : null
      viewsHistory.histories = this.histories || []
      this.modal = false
      Storage.setItem('views-history', viewsHistory)
      this.$bvModal.hide('views-history-dialog')
      this.$emit('onOk', this.selectedView)
    },
    handleCancel() {
      const viewsHistory = Storage.getItem('views-history') || {}
      this.selectedViewId = viewsHistory.activeId ? viewsHistory.activeId : null
      this.selectedView = this.histories[viewsHistory.activeId] || []
      this.modal = false
      this.$bvModal.hide('views-history-dialog')
      this.$emit('onCancel')
    },
    clearHistory() {
      if (this.histories.length === 0) {
        return
      }
      const viewsHistory = Storage.getItem('views-history') || {}
      viewsHistory.activeId = null
      viewsHistory.histories = []
      Storage.setItem('views-history', viewsHistory)

      this.histories = []
      this.selectedView = []
      this.selectedViewId = null
      this.$emit('onClear')
    },
    handleViewClick(viewId, data) {
      this.selectedViewId = viewId
      this.selectedView = [
        ...this.defaultColumns,
        ...data,
      ]
    },
  },
}
</script>
