<template>
  <tbody>
    <tr v-if="showHeader" class="table-head--row">
      <td
        v-for="item in heads"
        :key="item.key"
        :class="{
          'th-cell-fixed': item.fixed
        }"
        @click="handleSort(item)"
      >
        <template v-if="item.fixed">
          <span class="line line-top" />
          <span class="line line-right" />
          <span class="line line-bottom" />
          <span class="line line-left" />
        </template>

        <div
          v-b-tooltip="{ title: item.description, placement: 'bottom', html: true, customClass: 'cp-custom-tooltip' }"
          class="cell-content d-flex align-items-center justify-content-between col-gap-2"
        >
          <span>
            {{ item.text }}
          </span>
          <feather-icon v-if="item.description" icon="InfoIcon" />
        </div>
      </td>
    </tr>
    <template v-for="(item, index) in groupData">
      <tr
        v-if="item.isShow"
        :key="index"
        :class="{
          'table-body--row': true,
          [`level-${item.level}`]: true,
          'active': item.statusName === 'Active',
          'inactive': item.statusName !== 'Active'
        }"
        :data-level1="item.level === 1 ? `level1-${item.masterKey}-${item.level1Key}` : null"
        :data-level2="item.level === 2 ? `level2-${item.masterKey}-${item.level1Key}-${item.level2Key}` : null"
        :data-level3="item.level === 3 ? `level3-${item.masterKey}-${item.level1Key}-${item.level2Key}-${item.level3Key}` : null"
        :data-index="index"
        :data-filter="[2, 3].includes(item.level) ? `level${item.level}${item.masterKey}${item[heads[0].key].replace(/\s|-/g, '')}` : null"
        @mouseover="handleMouseEvent($event, item.level, item.masterKey, item[heads[0].key])"
        @mouseout="handleMouseEvent($event, item.level, item.masterKey, item[heads[0].key])"
      >
        <td
          v-for="(h, h_index) in heads"
          :key="h.key"
          :class="{
            'table-cell': true,
            'td-cell-fixed': h.fixed
          }"
        >
          <div :class="{
            'cell-content d-flex': true,
            [h.key]: true,
            'justify-center': h.alignContent === 'center',
            'justify-start': item.alignContent === 'left',
            'justify-end': item.alignContent === 'right',
            'line-level-1': h_index === 0 && item.level !== 0,
            'no-y': h_index === 0 && item.level !== 1,
          }"
          >
            <span
              v-if="isLevel2(h_index, item.level)"
              :class="{
                'line-level-2': isLevel2(h_index, item.level) && !item.isBelongToLastOfLevel2,
                'no-y': h_index === 0 && item.level !== 2,
                'a-half-of-x': isLevel2(h_index, item.level) && item.isLastOfLevel2
              }"
            />
            <span
              v-if="isLevel3(h_index, item.level)"
              :class="{
                'line-level-3': isLevel3(h_index, item.level),
                'no-y': h_index === 0 && item.level !== 3,
                'a-half-of-x': isLevel3(h_index, item.level) && item.level3LastItem
              }"
            />
            <span
              v-if="h_index === 0 && [0, 1, 2].includes(item.level)"
              flat
              icon
              :ripple="false"
              class="cursor-pointer"
              @click="handleExpand(item, index)"
            >
              <feather-icon
                v-if="!rowsExpanded.includes(index)"
                icon="PlusSquareIcon"
                size="24"
              />
              <feather-icon
                v-else
                icon="MinusSquareIcon"
                size="24"
              />
            </span>
            <span style="line-height: 24px">
              <slot :name="`cell-${h.key}`" :data="{cellValue: item[h.key], rowData: item, level: item.level}">
                {{ item[h.key] ? `${item[h.key]}` : '-' }}
              </slot>
            </span>
          </div>
        </td>
      </tr>
    </template>
  </tbody>
</template>

<script>
import {
  Storage,
} from '@/utils/utils'

import './style.scss'

export default {
  name: 'GroupedTableContent',
  props: {
    heads: {
      type: Array,
      default: () => [],
    },
    bodyData: {
      type: Array,
      default: () => [],
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    groupId: {
      type: String,
      default: '',
    },
    expandAll: {
      type: Object,
      default: () => ({
        level: 'all',
        status: false,
      }),
    },
    enableHighlight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      groupData: [],
      rowsExpanded: [],
      rowsShown: [],
      isExpandAll: false,
      currentSort: {},
    }
  },
  watch: {
    bodyData() {
      this.initGroupData()
    },
    expandAll(newValue, oldValue) {
      const { level, status } = newValue
      if (level === 'all') {
        this.expandAllLevel(status)
      } else {
        this.expandByLevel(newValue, oldValue)
      }
    },
  },
  mounted() {
    this.initGroupData()
  },
  methods: {
    initGroupData() {
      const { rowsShown, rowsExpanded } = this.initStorageData()

      this.groupData = [...this.bodyData]
      this.rowsExpanded = rowsExpanded && rowsExpanded[this.groupId] ? rowsExpanded[this.groupId].rowsExpanded : []
      this.rowsShown = rowsShown && rowsShown[this.groupId] ? rowsShown[this.groupId].rowsShown : []

      if (this.groupData.length > 0 && this.rowsShown.length > 0) {
        this.rowsShown.forEach(row => {
          this.groupData[row] = {
            ...this.groupData[row],
            isShow: true,
          }
        })
      }
    },
    initStorageData() {
      let rowsExpanded = Storage.getItem('rows-expanded') || {}
      let rowsShown = Storage.getItem('rows-shown') || {}

      if (!rowsExpanded && !rowsShown) {
        rowsExpanded = {
          [this.groupId]: [],
        }
        rowsShown = {
          [this.groupId]: [],
        }
      }
      return { rowsShown, rowsExpanded }
    },
    /**
     * Check current item is level 2
     * @param {Number} - Table Column index
     * @param {Number} - Item level
     */
    isLevel2(hIndex, level) {
      return hIndex === 0 && level !== 0 && level !== 1
    },
    /**
     * Check current item is level 3
     * @param {Number} - Table Column index
     * @param {Number} - Item level
     */
    isLevel3(hIndex, level) {
      return hIndex === 0 && ![0, 1, 2].includes(level)
    },
    handleMouseEvent(e, level, masterKey, value) {
      if (![2, 3].includes(level) || !this.enableHighlight) return
      const siblings = document.querySelectorAll(`tr[data-filter="level${level}${masterKey}${value.replace(/\s|-/g, '')}"]`)
      siblings.forEach(sibling => {
        if (sibling.classList.contains('comparing')) {
          sibling.classList.remove('comparing')
        } else {
          sibling.classList.add('comparing')
        }
      })
    },
    handleExpand(item, rowIndex) {
      const { level: currentItemLevel } = item
      const { rowsShown, rowsExpanded } = this.initStorageData()

      this.rowsExpanded = !this.rowsExpanded.includes(rowIndex)
        ? [...this.rowsExpanded, rowIndex]
        : this.rowsExpanded.filter(i => i !== rowIndex)

      this.groupData = this.groupData.map((el, index) => {
        const element = el
        const isLevel1Child = element.level1Key === item.level1Key
        const isLevel2Child = element.level2Key === item.level2Key

        if (currentItemLevel + 1 === 1 && element.level === 1) {
          element.isShow = !element.isShow

          this.rowsExpanded = this.rowsExpanded.filter(i => i !== index)
          this.rowsShown = element.isShow
            ? [...this.rowsShown, index]
            : this.rowsShown.filter(i => i !== index)
        } else if (currentItemLevel + 1 === 1 && element.level > 1) {
          element.isShow = false

          this.rowsExpanded = this.rowsExpanded.filter(i => i !== index)
          this.rowsShown = this.rowsShown.filter(i => i !== index)
        }

        if (currentItemLevel + 1 === 2 && isLevel1Child && element.level === 2) {
          element.isShow = !element.isShow

          this.rowsExpanded = this.rowsExpanded.filter(i => i !== index)
          this.rowsShown = element.isShow
            ? [...this.rowsShown, index]
            : this.rowsShown.filter(i => i !== index)
        } else if (currentItemLevel + 1 === 2 && isLevel1Child && element.level > 2) {
          element.isShow = false

          this.rowsExpanded = this.rowsExpanded.filter(i => i !== index)
          this.rowsShown = this.rowsShown.filter(i => i !== index)
        }

        if (currentItemLevel + 1 === 3 && element.level === 3 && isLevel1Child && isLevel2Child) {
          element.isShow = !element.isShow

          this.rowsShown = element.isShow
            ? [...this.rowsShown, index]
            : this.rowsShown.filter(i => i !== index)
        }
        return element
      })

      Storage.setItem('rows-shown', {
        ...rowsShown,
        [this.groupId]: {
          ...rowsShown[this.groupId],
          rowsShown: this.rowsShown,
        },
      })
      Storage.setItem('rows-expanded', {
        ...rowsExpanded,
        [this.groupId]: {
          ...rowsExpanded[this.groupId],
          rowsExpanded: this.rowsExpanded,
        },
      })
    },
    expandAllLevel(status) {
      const { rowsShown, rowsExpanded } = this.initStorageData()
      const { length } = this.groupData
      let RowsExpanded = []
      let RowsShown = []

      const res = []
      let i = 0
      while (i < length) {
        const item = this.groupData[i]
        RowsExpanded = status && item.level !== 3 ? [...RowsExpanded, i] : RowsExpanded
        RowsShown = status && item.level !== 0 ? [...RowsShown, i] : RowsShown

        const isShow = item.level !== 0 ? status : true
        res[i] = {
          ...item,
          isShow,
        }
        i += 1
      }
      this.groupData = res
      this.rowsExpanded = RowsExpanded
      this.rowsShown = RowsShown
      Storage.setItem('rows-shown', {
        ...rowsShown,
        [this.groupId]: {
          ...rowsShown[this.groupId],
          rowsShown: RowsShown,
        },
      })
      Storage.setItem('rows-expanded', {
        ...rowsExpanded,
        [this.groupId]: {
          ...rowsExpanded[this.groupId],
          rowsExpanded: RowsExpanded,
        },
      })
    },
    expandByLevel(newValue, oldValue) {
      const { rowsShown, rowsExpanded } = this.initStorageData()
      const { level, status } = newValue
      const { level: oldLevel, status: oldStatus } = oldValue
      const { length } = this.groupData
      let RowsExpanded = []
      let RowsShown = []

      if ((oldLevel === 'all' || oldLevel === 0) && !oldStatus && level === 1 && !status) return

      const res = []
      let i = 0
      while (i < length) {
        const item = this.groupData[i]
        if (status) {
          RowsExpanded = item.level <= level ? [...RowsExpanded, i] : RowsExpanded
          RowsShown = item.level <= level + 1 ? [...RowsShown, i] : RowsShown
        } else {
          RowsExpanded = item.level < level ? [...RowsExpanded, i] : RowsExpanded
          RowsShown = item.level <= level ? [...RowsShown, i] : RowsShown
        }

        const isShow = item.level === 0
          || (status && item.level > 0 && item.level <= level + 1)
          || (!status && item.level > 0 && item.level === level)
          || false
        res[i] = {
          ...item,
          isShow,
        }
        i += 1
      }

      this.groupData = res
      this.rowsExpanded = RowsExpanded
      this.rowsShown = RowsShown
      Storage.setItem('rows-shown', {
        ...rowsShown,
        [this.groupId]: {
          ...rowsShown[this.groupId],
          rowsShown: RowsShown,
        },
      })
      Storage.setItem('rows-expanded', {
        ...rowsExpanded,
        [this.groupId]: {
          ...rowsExpanded[this.groupId],
          rowsExpanded: RowsExpanded,
        },
      })
    },
    handleSort(item) {
      const { key, sortable, datatype } = item
      if (!sortable) return

      if (this.currentSort.key === key) {
        this.currentSort = {
          ...this.currentSort,
          datatype: datatype || 'number',
          value: this.currentSort.value === 'asc' ? 'desc' : 'asc',
        }
      } else {
        this.currentSort = {
          key,
          datatype: datatype || 'number',
          value: 'asc',
        }
      }

      this.$emit('onSort', this.currentSort)
    },
  },
}
</script>

<style lang="scss" scoped>
.cp-custom-tooltip {
  ::v-deep.tooltip-inner {
    text-align: left;
  }
}
</style>
