<template>
  <v-layout column class="block-component mb-3">
    <v-flex xs12 class="d-flex">
      <div class="filter-title">
        {{ title }}
      </div>
    </v-flex>
    <v-flex xs12 class="d-flex">
      <div class="filter-content w-100">
        <slot>
          Content
        </slot>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import './style.scss'

export default {
  name: 'BlockComponent',
  props: {
    title: {
      type: String,
      default: 'Title',
    },
  },
}
</script>
