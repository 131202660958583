<template>
  <div class="table-filters">
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-channel-filter
      variant="outline-primary"
      style="width: 105px"
    >
      <feather-icon
        icon="FilterIcon"
        size="16"
      />
      Filter
    </b-button>

    <b-modal
      id="modal-channel-filter"
      scrollable
      title="Filter"
      cancel-variant="outline-secondary"
      size="lg"
      class="main-filter-drawer"
    >
      <div class="main-filter-drawer">
        <div class="main-filter-content">
          <!-- Date filter -->
          <BlockComponent title="How to display">
            <DatePickerComponent v-model="results.date" />
          </BlockComponent>

          <!-- Campaign data -->
          <BlockComponent title="Campaign data">
            <FilterCampaignDataComponent
              :campaign-data="filterDatas.campaignData || {}"
              :value="results.campaign"
              @onChange="(data) => handleDataChange('campaign', data)"
            />
          </BlockComponent>

          <!-- Campaign status -->
          <BlockComponent title="Campaign Status">
            <FilterStatusComponent
              :status-data="filterDatas.campaignStatusData || []"
              :value="results.status"
              @onChange="(data) => handleDataChange('status', data)"
            />
          </BlockComponent>

          <!-- Product -->
          <BlockComponent title="Product">
            <FilterProductComponent
              :product-data="filterDatas.productData || {}"
              :value="results.product"
              @onChange="(data) => handleDataChange('product', data)"
            />
          </BlockComponent>

          <!-- Country -->
          <BlockComponent title="Country">
            <FilterCountryComponent
              :country-data="filterDatas.countryData || []"
              :value="results.country"
              @onChange="(data) => handleDataChange('country', data)"
            />
          </BlockComponent>

          <!-- Staff -->
          <BlockComponent title="Staff">
            <FilterStaffComponent
              :staff-data="filterDatas.staffData || {}"
              :value="results.staff"
              @onChange="(data) => handleDataChange('staff', data)"
            />
          </BlockComponent>
        </div>
      </div>
      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-button variant="secondary" @click="handleCancel()">
            Cancel
          </b-button>
          <div class="d-flex col-gap-4">
            <b-button variant="warning" @click="handleReset()">
              Reset
            </b-button>
            <b-button variant="success" @click="handleOk()">
              Ok
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  Storage,
  defaultFilter,
  defaultPerPage,
} from '@/utils/utils'
import BlockComponent from '../BlockComponent/BlockComponent.vue'
import DatePickerComponent from '../DatePickerComponent/DatePickerComponent.vue'
import FilterCampaignDataComponent from '../FilterCampaignDataComponent/FilterCampaignDataComponent.vue'
import FilterProductComponent from '../FilterProductComponent/FilterProductComponent.vue'
import FilterCountryComponent from '../FilterCountryComponent/FilterCountryComponent.vue'
import FilterStaffComponent from '../FilterStaffComponent/FilterStaffComponent.vue'
import FilterStatusComponent from '../FilterStatusComponent/FilterStatusComponent.vue'

import './style.scss'

export default {
  name: 'FilterComponent',
  components: {
    BButton,
    BModal,
    BlockComponent,
    DatePickerComponent,
    FilterCampaignDataComponent,
    FilterProductComponent,
    FilterCountryComponent,
    FilterStaffComponent,
    FilterStatusComponent,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    filterDatas: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return ({
      storageKey: 'filter-data',
      isFilterDialog: false,
      dateRange: {},
      results: {},
    })
  },
  created() {
    this.getStoragedFilterValue()
  },
  methods: {
    openSettingDialog() {
      this.isFilterDialog = true
    },
    handleOk() {
      this.closePopup()
      if (!this.results.status.status.length) {
        this.results.status.status = JSON.parse(JSON.stringify(defaultFilter.status.status))
      }
      if (!this.results.country.country.length) {
        this.results.country.country = JSON.parse(JSON.stringify(defaultFilter.country.country))
      }
      this.isFilterDialog = false
      this.results = {
        ...this.results,
        page: 1,
      }
      Storage.setItem(this.storageKey, this.results)
      this.$emit('onChange', this.results)
    },
    handleCancel() {
      this.closePopup()
      this.isFilterDialog = false
      this.getStoragedFilterValue()
    },
    handleReset() {
      this.results = JSON.parse(JSON.stringify(defaultFilter))
      this.results = {
        ...this.results,
        page: 1,
        per_page: defaultPerPage,
      }
    },
    handleDataChange(key, data) {
      this.results = {
        ...this.results,
        [key]: data,
      }
    },
    getStoragedFilterValue() {
      this.results = Storage.getItem(this.storageKey) || JSON.parse(JSON.stringify(defaultFilter))
    },
    closePopup() {
      this.$bvModal.hide('modal-channel-filter')
    },
    // handleToggleButton() {
    //   if (this.isFilterDialog) {
    //     this.handleCancel()
    //     return
    //   }
    //   this.isFilterDialog = true
    // },
  },
}
</script>
