<template>
  <div class="filter--status">
    <HeroVueSelectMultiple
      id="status"
      v-model="selectedStatus"
      label="Status"
      placeholder="Select Status"
      :options="statusData"
      @input="handleDataChange"
    />
  </div>
</template>

<script>
import HeroVueSelectMultiple from '@/views/components/form/selects/HeroVueSelectMultiple.vue'

export default {
  name: 'FilterStatusComponent',
  components: {
    HeroVueSelectMultiple,
  },
  props: {
    statusData: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({
        status: [],
      }),
    },
  },
  data() {
    return ({
      selectedStatus: [],
    })
  },
  watch: {
    value() {
      this.selectedStatus = this.value.status
    },
  },
  mounted() {
    this.selectedStatus = this.value.status
  },
  methods: {
    handleDataChange(value) {
      this.$emit('onChange', { status: value })
    },
  },
}
</script>
