<template>
  <v-content class="new-channel-performance-wraper">
    <b-card-actions
      ref="channellist"
      no-actions
      no-body
    >
      <TableComponent
        :header="header"
        :table-data="tableData"
        :response-result="responseResult"
        :search-by="['campaign_name']"
        :filter-datas="filterDatas"
        :pagination-data="paginationData"
        :campaigns="searchResults"
        :show-fetch-data="canAccessFetchData"
        @onFilterUpdate="handleFilterDataUpdate"
        @onPageUpdate="handlePagination"
        @onSearchCampaign="handleSearchCampaign"
        @fetch-data="handleFetchData"
      />
    </b-card-actions>
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
    >
      {{ snackbar.message }}
      <v-btn
        flat
        icon
        @click="snackbar.open = false"
      >
        <v-icon dark>
          close
        </v-icon>
      </v-btn>
    </v-snackbar>
  </v-content>
</template>

<script>
import { mapMutations } from 'vuex'
import debounce from 'lodash/debounce'
import { UPDATE_FILTER_DATAS } from '@/store/mutation-types'
import TableComponent from '@/views/components/table/HeroChannelTable.vue'
import { tableHeads, transformApiData } from '@/utils/helper'
import ChannelService from '@/services/ChannelService'
import { Storage } from '@/utils/utils'
import Store from '@/store/index'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import './style.scss'
import Toastification from '@/services/Toastification'
// import LocalStorageService from '@/services/LocalStorageService'

export default {
  name: 'NewChannelPerformance',
  components: {
    TableComponent,
    BCardActions,
  },
  data() {
    return ({
      header: tableHeads,
      tableData: [],
      dateFormat: 'DD/MM/YYYY',
      paginationData: {
        from: 0,
        to: 0,
        total: 0,
        currPage: 1,
        totalPages: 0,
        perPages: 50,
      },
      searchResults: [],
      filtersData: {},
      snackbar: {
        open: false,
        message: '',
      },
      canAccessFetchData: false,
      responseResult: [],
    })
  },
  computed: {
    filterDatas() {
      return this.$store.getters['channel/filterDatas']
    },
  },
  created() {
    const perList = this.$store.getters['heroAiAuthentications/getPermissions']
    this.canAccessFetchData = perList.filter(x => x.name === 'channel-perf-update-data').lenght > 0
    this.handleSearchCampaign = debounce(this.handleSearchCampaign, 300)
  },
  mounted() {
    const filters = Storage.getItem('filter-data')
    this.getFilterData()
    if (!filters) return
    this.getTableData(filters)
  },

  methods: {
    /**
     * รับข้อมูลตัวกรองสำหรับ Filter Panel
     *
     */
    async getFilterData() {
      const { data } = await ChannelService.getFilter()
      if (data) {
        Store.commit(`channel/${UPDATE_FILTER_DATAS}`, data)
        // this.updateFilterDatas(data)
      }
    },

    /**
     * รับข้อมูล Channel Performance Data จาก Redshift ผ่าน HeroAI
     *
     */
    async getTableData(filters) {
      this.$refs.channellist.showLoading = true
      try {
        // แยกข้อมูลฟิลเตอร์ออกเป็นแต่ล่ะตัว
        const {
          campaign,
          country,
          date,
          product,
          staff,
          page,
          per_page,
          status,
        } = filters

        // ตั้งค่าตัวแปร params ที่จะเอาไว้ส่งไปเป็นเงื่อนไขในการกรองข้อมูล
        let params = {
          country_id: [
            '5b63bbaf5e01bb6948695559',
          ],
          page,
          per_page,
        }

        // Cycle และ Date Range
        if (date) {
          if (date.howToDisplaySelected === 'compare-by-cycles') {
            params = {
              ...params,
              compare_cycles_before: +date.period,
            }
          }

          if (date.howToDisplaySelected === 'compare-by-dates') {
            params.date_range = []
            date.datePicker.forEach(item => {
              params = {
                ...params,
                date_range: [
                  ...params.date_range,
                  { from_time: item[0], to_time: item[1] },
                ],
              }
            })
          }

          // Show Current Contract
          if (date.howToDisplaySelected === 'display-current-contract-cycles') {
            params = {
              ...params,
              get_current_contract: 1,
            }
          }
        }

        // Campaign Status
        if (status && status.status && status.status.length > 0) {
          params = {
            ...params,
            campaign_status_ids: status.status,
          }
        }

        // Country
        if (country && country.country) {
          params = {
            ...params,
            country_id: country.country,
          }
        }

        // กลุ่ม Campaign
        if (campaign) {
        // Campaign Name
          if (campaign.campaignName) {
            params = {
              ...params,
              campaign_name: campaign.campaignName,
            }
          }

          // Kpi
          if (campaign.kpi.length > 0) {
            params = {
              ...params,
              kpi_unit_ids: campaign.kpi,
            }
          }

          // Objective
          if (campaign.objective.length > 0) {
            params = {
              ...params,
              objective_ids: campaign.objective,
            }
          }
        }

        // กลุ่ม Product
        if (product) {
        // Product Family
          if (product.productFamilys.length > 0) {
            params = {
              ...params,
              product_types: product.productFamilys,
            }
          }

          // Product Name
          if (product.products.length > 0) {
            params = {
              ...params,
              product_ids: product.products,
            }
          }
        }

        // กลุ่ม Staff
        if (staff) {
        // Account Manager
          if (staff.ams.length > 0) {
            params = {
              ...params,
              account_manager_ids: staff.ams,
            }
          }

          // Optimizer
          if (staff.optimizers.length > 0) {
            params = {
              ...params,
              optimizer_ids: staff.optimizers,
            }
          }
        }

        // บันทึกตัวแปร params ที่สมบูรณ์เข้าตัวแปร filterData
        this.filtersData = params

        // รับข้อมูล Channel Performance Data จาก Redshift ผ่าน HeroAI
        const { data, error } = await ChannelService.getTableData(params)
        if (!data) {
          console.log(error)
          return
        }

        // แยกข้อมูล Channel Performance จาก Response
        const { channelPerformances } = data

        if (!channelPerformances) {
          this.$toast(Toastification.getContentInfo('The information you are looking for is not found.'))
        }

        // แยกข้อมูล Pagination จาก Channel Performance
        const {
          current_page: currPage,
          last_page: totalPages,
          per_page: perPages,
          from,
          to,
          total,
        } = channelPerformances

        // แปลงรูปแบบข้อมูล Channel Performance ให้เหมาะสมสำหรับการแสดงผลบนตาราง
        this.responseResult = transformApiData(channelPerformances)

        this.paginationData = {
          from,
          to,
          total,
          currPage,
          totalPages,
          perPages,
        }
        setTimeout(() => {
          this.handlePagination(filters)
        }, 100)
        // นำข้อมูลใส่ตารางและกำหนด Pagination
        // this.tableData = results
      } catch (error) {
        console.log(error)
      } finally {
        this.$refs.channellist.showLoading = false
      }
    },
    handlePagination(filters) {
      if (this.responseResult) {
        if (!filters) {
          this.paginationData.from = 1
          this.paginationData.to = 50
          this.paginationData.currPage = 1
          this.paginationData.totalPages = Math.ceil(this.responseResult.length / 50)
          this.paginationData.perPages = 50
          this.tableData = this.responseResult.slice((this.paginationData.currPage - 1) * this.paginationData.perPages, this.paginationData.currPage * this.paginationData.perPages)
        } else {
          this.paginationData.currPage = filters.page
          this.paginationData.perPages = filters.per_page
          this.paginationData.from = ((this.paginationData.currPage - 1) * this.paginationData.perPages) + 1
          const toPage = (this.paginationData.currPage * this.paginationData.perPages)
          this.paginationData.to = toPage > this.responseResult.length ? this.responseResult.length : toPage
          this.paginationData.totalPages = Math.ceil(this.responseResult.length / this.paginationData.perPages)
          this.tableData = this.responseResult.slice((this.paginationData.currPage - 1) * this.paginationData.perPages, this.paginationData.currPage * this.paginationData.perPages)
        }
      }
    },
    handleFilterDataUpdate(data) {
      this.getTableData(data)
    },

    async handleSearchCampaign(name) {
      if (!name || name.length < 3) return
      const res = await ChannelService.searchCampaign(name)
      if (res.success) {
        this.searchResults = res.data.data
      }
    },
    async handleFetchData(data) {
      const {
        campaignId,
        startDate: FromDate,
        endDate: ToDate,
      } = data
      if (!campaignId || !FromDate || !ToDate) return
      const res = await ChannelService.fetchData(campaignId, { FromDate, ToDate })

      this.snackbar = {
        open: true,
        message: res.data.message,
      }
    },
    ...mapMutations({
      updateFilterDatas: UPDATE_FILTER_DATAS,
    }),
  },
}
</script>
